<template>
 <div>
    <v-app-bar
      id="home-app-bar"
      app
      xs
      :class="base"
      elevation="1"
      elevate-on-scroll
       height="90"
      color='white'
            
    >
    
      <v-img
      :src="require('../../../assets/cropped-logo1.png')"
       
        
        class="mr-3 hidden-xs-only"
        contain
        max-width="200"
         
      />


     <v-img
         
        gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
         class="mr-3 hidden-xs-only"
        contain
        max-width="100"
        width="100%"
      ></v-img>
      

            <v-spacer />

      <div>
        <v-row >
          <v-spacer />
        <v-btn
        
          v-for="icon in icons"
          :key="icon.index"
         :href="icon.url " 
         target="_blank"
          
          icon
        >
          <v-icon
          :color="icon.color"
           size="25px">
           
           {{ icon.icono }}
          </v-icon>
        </v-btn>
       
         
      </v-row>
      <v-divider></v-divider>
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
          
        >
          <v-tab
         
            v-for="name in items"
                        :key="name.name" 
          >
           <router-link  
                            :to=" 

                                    name.ruta
                            "
                             
                            >
                            <v-btn
                                depressed
                                color="light-blue white--text"
                              >
                              {{ name.name }}
                              </v-btn>
                             </router-link>
          </v-tab>
        </v-tabs>
      </div>
          <div  v-if="!isMobile()">
             <v-app-bar-nav-icon
             color="light-blue"
                style="display:none"
                @click.stop="drawer = !drawer"  
            ></v-app-bar-nav-icon>
          </div>
           <div  v-else>
             <v-app-bar-nav-icon
             color="light-blue"
                style="display:block"
                @click.stop="drawer = !drawer"  
            ></v-app-bar-nav-icon>
          </div>
           
            <!-- aqui empieza -->
          

            <!-- aqui trmina -->
              </v-app-bar>
                <v-navigation-drawer
                         app
               
                  v-model="drawer"
                    
             temporary
                >
                  <v-list
                    nav
                    dense
                  >
                    <v-list-item-group
                      v-model="group"
                      active-class="deep-purple--text text--accent-4"
                    >
                      <v-list-item
                       v-for="name in items"
                        :key="name.name"
                        
                        
                        min-width="96"
                        text>
                      <v-list-item-content>
            <v-list-item-title>
             <router-link 
                            :to=" 

                                    name.ruta
                            "
                             
                            >
                            {{ name.name }}
                             </router-link
                        ></v-list-item-title>
          </v-list-item-content>
                      </v-list-item>
 
                    </v-list-item-group>
                  </v-list>
                  
                </v-navigation-drawer>        

                

<!-- fin  -->
    </div>
</template>

<script>
//import auth from "../../../mixins/auth";
export default {
    name: "HomeAppBar",
      url_:"",
   // mixins: [auth],
 

    data: () => ({
      drawer: false,
      none:'display:none',
      group: null,
      //drawer: null,
      text:"white--text lighten-1--text",
      bg:"primary",
      base:"video",
      items: [
       {name:'Inicio',icon: 'mdi-view-dashboard', ruta:'/',color:'purple darken-1'},
       // {name:'Galería',icon: 'mdi-human', ruta:'/galeria',color:'cyan darken-4'},
        {name:'Articulos',icon: 'mdi-clipboard-list', ruta:'/articulos',color:'green darken-4'},
        {name:'Congresos',icon: 'mdi-file-document', ruta:'/congresos'},
        {name:'Ubicación',icon: 'mdi-human', ruta:'/ubicacion',color:'cyan darken-4'},
        {name:'Agendar Citas',icon: 'mdi-chart-bar', ruta:'/agenda',color:'orange darken-4'},
         
      ],
       icons: [],
    }),
    mounted() {
          this.url_ = this.$store.state.link;
         this.cargarInformacion();
         this.CargarRedes();
     //  this.pantalla();
      window.onscroll = () => {
     
    };
    },
    methods: {
       cargarInformacion() {
           
            
             let that = this;
             var url =  this.url_ + "api/empresa";
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                    that.informacion = response.data.empresa;
              //  console.log(that.informacion);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                });
            },
              CargarRedes() {
           
            
             let that = this;
             var url =  this.url_ + "api/red_social";
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                    that.icons = response.data.redes_sociales;
              //  console.log(that.icons);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                });
            },
       isMobile() {
          if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
           
            
            return true

          } else {
                
            return false
          }
        },
  //     pantalla(){
  // if (screen.width < 1024) 
  //      { 
  //       this.none = 'display:none';
  //      }  
  //       else 
  //       {
  //        this.none = 'display:block';
  //       }
          
  //             //this.drawer = false
  //     },
      changeColor() {
     
    },
    },
    watch: {
       
      group () {
      
              },
    },
    computed: {
       
      
    },
  }
</script>

 